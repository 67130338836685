<template>
  <div class="privacy-page">
    <div class="privacy-settings-title">
      PRIVACY SETTINGS
    </div>
    <b-row class="pt-3 justify-content-center privacy-settings-body">
      <b-col xl="4" lg="5" md="7" sm="9" class="justify-content-center">
        <toggle-button
          class="show-privacy"
          :width="30"
          :height="15"
          :value="ShowTel"
          v-model="ShowTel"
          :sync="true"
        />
        <label class="show-privacy-lable">Display my telephone number to my contacts</label>
      </b-col>
    </b-row>
    <b-row class="pb-3 justify-content-center privacy-settings-body">
      <b-col xl="4" lg="5" md="7" sm="9" class="justify-content-center">
        <toggle-button
          class="show-privacy"
          :width="30"
          :height="15"
          :value="ShowEmail"
          v-model="ShowEmail"
          :sync="true"
        />
        <label class="show-privacy-lable">Display my email address to my contacts</label>
      </b-col>
    </b-row>
    <b-row class="mt-1 justify-content-center privacy-settings-body">
      <b-col xl="4" lg="5" md="7" sm="9" class="mb-2 pt-4 pb-2 justify-content-center">
        <div class="privacy-settings-support-text">
          <a
            class="privacy-settings-support-text"
            href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com"
          > Need support? Click here!
          </a>
        </div>
      </b-col>
      <b-col sm="12" class="justify-content-center">
        <b-button
          class="privacy-setting-button"
          block
          :disabled="false"
          type="submit"
          variant="info"
          v-on:click="savePrivacyInfo"
        >SAVE SETTINGS</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserPrivacySettings',
  components: {
  },
  data() {
    return {
      ShowTel: false,
      ShowEmail: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
    }),
  },
  methods: {
    ...mapActions({
      editUserInfo: 'user/editUserInfo',
    }),
    async savePrivacyInfo() {
      const { ShowEmail, ShowTel } = this;
      try {
        const data = {
          display_configuration: {
            show_phone_number: ShowTel,
            show_email: ShowEmail,
          },
        };
        await this.editUserInfo(data);
        this.$notify({
          group: 'userAlerts',
          title: 'Success',
          text: 'Your privacy settings have been saved!',
          type: 'success',
        });
      } catch (err) {
        console.log('err', err);
      }
    },
  },
  watch: {
    'userInfo.display_configuration': {
      immediate: true,
      handler(display_configuration) {
        if (display_configuration) {
          this.ShowEmail = display_configuration.show_email;
          this.ShowTel = display_configuration.show_phone_number;
        }
      },
      deep: true,
    },
  },
};
</script>
<style src='./Settings.css' scoped></style>
<style scoped>
.privacy-page {
  margin-top: 0.25rem;
}
.privacy-settings-title {
  display: none;
}
.privacy-settings-body {
  background-color: #fff;
}
.privacy-settings-support-text {
  font-family: Arial;
  font-size: 15px;
  line-height: 1.13;
  text-align: center;
  color: #BD9D5F;
}
.privacy-setting-button {
  margin: 0.4rem 0 20px 0;
  padding: 10px;
  font-family: Arial;
  font-size: 15px;
  line-height: 1.13;
  color: #ffffff;
  background: #BD9D5F !important;
  border: unset !important;
  text-align: center !important;
}
.show-privacy {
  margin: 8px 20px 11px 9px;
}
.show-privacy-lable {
  font-family: Arial;
  font-size: 12px;
  line-height: 1.17;
  color: #666666;
}

@media (max-width:768px) {
  .privacy-page {
    margin-top: 0.65rem;
  }
  .privacy-settings-title {
    display: block;
    padding: 16px;
    border-radius: 0 10px 0 0;
    text-align: center;
    background-color: #fff;
    margin-bottom: 2px;
  }
  .privacy-page .privacy-settings-body {
    margin: 0;
  }
  .privacy-page .privacy-settings-body:first-child {
    border-radius: 0 10px 0 0;
  }
  .privacy-page .privacy-settings-body:last-child {
    border-radius: 0 0 10px 10px;
  }
  .privacy-setting-button {
    border-radius: 0 10PX 10PX 10PX;
  }
}
</style>
